import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import money from '../assets/money.png'

// styles
const MoveWrapper = keyframes`
  0% {
    margin-left: 0;
    margin-top: 0;
  }
  100% {
    margin-left: 100vw;
    margin-top: -100vh;
  }
  `
const Wrapper = styled.div`
  position: absolute;
  display: flex;
  height: auto;
  width: auto;
  z-index: 1;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-out;
  }

  ${props =>
    props.shouldMove &&
    css`
      animation: ${MoveWrapper} 1.2s ease-in forwards;
    `}
`
const timing = 'animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)'
const Animation = (deg, maxSize) => keyframes`
  from {
    ${timing};
  }
  0% {
    opacity: 0;
    transform: rotate(${deg}deg) scale3d(${0.3 * maxSize}, ${0.3 * maxSize}, ${
  0.3 * maxSize
});
  }
  20% {
    ${timing};
    transform: rotate(${deg}deg) scale3d(${1.1 * maxSize}, ${1.1 * maxSize}, ${
  1.1 * maxSize
});
  }
  40% {
    ${timing};
    transform: rotate(${deg}deg) scale3d(${0.9 * maxSize}, ${0.9 * maxSize}, ${
  0.9 * maxSize
});
  }
  60% {
    ${timing};
    opacity: 1;
    transform: rotate(${deg}deg) scale3d(${1.03 * maxSize}, ${
  1.03 * maxSize
}, ${1.03 * maxSize});
  }
  80% {
    ${timing};
    transform: rotate(${deg}deg) scale3d(${0.97 * maxSize}, ${
  0.97 * maxSize
}, ${0.97 * maxSize});
  }
  to {
    ${timing};
    opacity: 1;
    transform: rotate(${deg}deg) scale3d(${maxSize}, ${maxSize}, ${maxSize});
  }
  `
const CoreImage = styled.img`
  position: absolute;
  width: 3em;
  height: auto;
  cursor: pointer;
  opacity: 0;

  &:nth-child(1) {
    opacity: 1;
    transform: scale(${props => props.startingSize});
  }
  &:nth-child(2) {
    margin-top: -4em;
    margin-left: 3.4em;
    transform: rotate(-32deg) scale(${props => props.maxSize});
    ${props =>
      props.visible === true &&
      css`
        opacity: 1;
        animation: ${Animation(-32, props.maxSize)} 0.4s forwards;
      `}
  }
  &:nth-child(3) {
    margin-top: -5.8em;
    margin-left: 1.8em;
    transform: rotate(18deg) scale(${props => props.maxSize});
    ${props =>
      props.visible === true &&
      css`
        opacity: 1;
        animation: ${Animation(18, props.maxSize)} 0.4s forwards;
      `}
  }
  &:nth-child(4) {
    margin-top: -7em;
    margin-left: 4em;
    transform: rotate(-32deg) scale(${props => props.maxSize});
    ${props =>
      props.visible === true &&
      css`
        opacity: 1;
        animation: ${Animation(-32, props.maxSize)} 0.4s forwards;
      `}
  }
  &:nth-child(5) {
    margin-top: -3.8em;
    margin-left: 6em;
    transform: rotate(-13deg) scale(${props => props.maxSize});
    ${props =>
      props.visible === true &&
      css`
        opacity: 1;
        animation: ${Animation(-13, props.maxSize)} 0.4s forwards;
      `}
  }
  &:nth-child(6) {
    margin-top: -1.8em;
    margin-left: 3.2em;
    transform: rotate(30deg) scale(${props => props.maxSize});
    ${props =>
      props.visible === true &&
      css`
        opacity: 1 !important;
        animation: ${Animation(30, props.maxSize)} 0.4s forwards;
      `}
  }
`
const Image = React.memo(props => {
  return <CoreImage {...props} />
})

function Main({ scale }) {
  const [shouldMove, setShouldMove] = React.useState(false)
  const [visibleDollars, setVisibleDollars] = React.useState([])
  const startingSize = scale || 1
  const maxSize = 0.6 * scale

  const cb = React.useCallback(async () => {
    await new Promise(resolve => setTimeout(resolve, 40))
    setVisibleDollars([...visibleDollars, visibleDollars.length])
  }, [visibleDollars])
  React.useEffect(() => {
    if (visibleDollars.includes(0) && visibleDollars.length < 6) {
      cb()
    }
  }, [visibleDollars, cb])

  const handleClick = async e => {
    e.preventDefault()
    if (visibleDollars.length === 0 || visibleDollars.length === 6) {
      setVisibleDollars([0])
      await new Promise(resolve => setTimeout(resolve, 450))
      setShouldMove(true)
    }
  }

  const firstVisible = visibleDollars.includes(1) || false
  const secondVisible = visibleDollars.includes(2) || false
  const thirdVisible = visibleDollars.includes(3) || false
  const fourthVisible = visibleDollars.includes(4) || false
  const fifthVisible = visibleDollars.includes(5) || false

  return (
    <Wrapper shouldMove={shouldMove}>
      <Image
        onClick={handleClick}
        src={money}
        key={0}
        maxSize={maxSize}
        startingSize={startingSize}
      />
      <Image
        src={money}
        key={1}
        visible={firstVisible}
        maxSize={maxSize}
        startingSize={startingSize}
      />
      <Image
        src={money}
        key={2}
        visible={secondVisible}
        maxSize={maxSize}
        startingSize={startingSize}
      />
      <Image
        src={money}
        key={3}
        visible={thirdVisible}
        maxSize={maxSize}
        startingSize={startingSize}
      />
      <Image
        src={money}
        key={4}
        visible={fourthVisible}
        maxSize={maxSize}
        startingSize={startingSize}
      />
      <Image
        src={money}
        key={5}
        visible={fifthVisible}
        maxSize={maxSize}
        startingSize={startingSize}
      />
    </Wrapper>
  )
}

const FlyingDollars = React.memo(props => {
  return <Main {...props} />
})
export default FlyingDollars
