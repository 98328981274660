import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://umber.co/social_img.png`,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:creator`,
          content: '@umber',
        },
        {
          property: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: `https://umber.co/social_img.png`,
        },
        {
          property: `twitter:image:alt`,
          content: `The Umber app icon glowing on top of a background comprised of traditional, high street banking app icons`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `snapchat:sticker`,
          content: `https://umber.co/snap_img.png`,
        },
        {
          property: `snapchat:app_id`,
          content: `bed7dfb7-7720-44d5-84af-a974b1afe428`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `give your bank account superpowers ⚡`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
