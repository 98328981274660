import * as React from 'react'
import styled from 'styled-components'

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 1.5em;
  width: 1.5em;
  border: 1.5px solid #fff;
  border-radius: 100%;

  opacity: ${({ opacity }) => opacity || 1};
  transition: opacity 0.3s ease-in-out;
`
const Number = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1em;
`

const CircleNumber = ({ number, opacity, style }) => {
  return (
    <Outline opacity={opacity} style={style}>
      <Number>{number}</Number>
    </Outline>
  )
}

export default CircleNumber
