import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import scrollTo from 'gatsby-plugin-smoothscroll'
import withReveal from 'react-reveal/withReveal'
import Fade from 'react-reveal/Fade'
import Seo from '../components/seo'
import FlyingDollars from '../components/flying-dollars'
import logo from '../assets/svgs/logo.svg'
import headline from '../assets/svgs/headline.svg'
import arrowdown from '../assets/svgs/arrow-down.svg'
import redo from '../assets/svgs/redo.svg'
import chevron from '../assets/svgs/chevron.svg'
import bolt from '../assets/svgs/bolt.svg'
import sofia from '../assets/images/sofia.png'
import notifications from '../assets/images/notifications.png'
import cardone from '../assets/images/card-one.png'
import cardthree from '../assets/images/card-three.png'
import cardfour from '../assets/images/card-four.png'
import cardfive from '../assets/images/card-five.png'
import cardsix from '../assets/images/card-six.png'
import tiktok from '../assets/tiktok.gif'
import insta from '../assets/insta.gif'
import feedItem from '../assets/feed-item.gif'
import intromp4hq from '../assets/intro-hq.mp4'
import intromp4lq from '../assets/intro-lq.mp4'
import { isMobile } from 'react-device-detect'
import { useForm } from '@formspree/react'
import useEventListener from '@use-it/event-listener'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import instagram from '../assets/svgs/insta.svg'
import Modal from './modal'
import CircleNumber from '../components/circle-number'
import { Transition } from '@headlessui/react'
import enableInlineVideo from 'iphone-inline-video'
import instaBtn from '../assets/svgs/insta-btn.svg'
import tiktokBtn from '../assets/svgs/tiktok-btn.svg'

// styles
const BREAKPOINT_TABLET = 1600
const BREAKPOINT_MOBILE = 1200
const Page = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  background-color: #000000;
  color: #ffffff;
  padding: 3em 20% 0 20%;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    padding: 3em 15% 0 15%;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 1em 5% 0 5%;
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  width: 100%;
`
const HeaderImage = styled(logo)`
  height: 60%;
  width: auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 50%;
  }
`
const HeaderLink = styled.p`
  font-size: 1.05em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: opacity(0.6);
  }

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 0.9em;
  }
`
const Intro = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 0;
  height: calc(100vh - 6em);
  width: 100%;
  margin: 0 auto 0 auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: calc(95vh - 3em);
  }
`
const IntroVideoMoveLeft = keyframes`
from {
  margin-left: 0%;
  padding-top: 7%;
}
to {
  margin-left: 10%;
  padding-top: 0;
}
`
const IntroVideoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`
const IntroVideo = styled.div`
  display: flex;
  z-index: 0;
  height: 85%;
  width: auto;
  max-width: 100%;
  padding-top: 7%;
  margin-top: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-top: 20%;
    max-width: 100vw;
  }

  ${props =>
    props.shouldMoveLeft != null &&
    props.shouldMoveLeft === true &&
    css`
      @media all and (min-width: ${BREAKPOINT_MOBILE}px) {
        animation: ${IntroVideoMoveLeft} 2s ease-out forwards;
      }
    `};

  video {
    margin: 0 auto;
    max-width: 100%;

    @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
      max-width: 100vw;
      height: auto;
    }
  }
`
const IntroCopy = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-left: 0;
  margin-top: 13%;
  opacity: 0;
  transition: all 0.3s ease-out;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: 20%;
  }

  ${props =>
    props.shouldAppear != null &&
    props.shouldAppear === true &&
    css`
      opacity: 1;
    `};
`
const IntroCopyImage = styled(headline)`
  height: auto;
  width: 140%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 15em;
  }
`
const IntroArrow = styled(arrowdown)`
  height: auto;
  width: 9%;
  margin-top: 1.2em;
  margin-left: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: opacity(0.6);
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 1.3em;
  }
`
const Waitlist = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15em;
  padding-bottom: 8em;
  width: 100%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    max-height: 150vh;
    margin-left: 0;
    padding-bottom: 25vh;
  }
`
const WaitlistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const WaitlistHeadingCopy = styled.h1`
  font-size: 3em;
  letter-spacing: -0.05em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2.5em;
  }
`
const WaitlistButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  width: 5.6em;
  margin-bottom: 0.4em;
`
const WaitlistButtonCopy = styled.p`
  font-weight: 700;
  font-size: 0.85em;
  margin: 0.37em;
  line-height: 120%;
`
const WaitlistSecondCopy = styled.h3`
  font-family: 'Eina 01 SemiBold';
  font-size: 1.8em;
  margin: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    padding-top: 0.2em;
    font-size: 1.4em;
    padding-bottom: 0.5em;
  }
`
const WaitlistThirdCopy = styled.h3`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.7em;
  margin: 0.2em 0 0 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.35em;
  }
`
const WaitlistCopyWrapper = styled.div`
  flex-basis: 70%;
  flex-direction: column;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-basis: 77%;
  }
`
const WaitlistSocialBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 30%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    flex-basis: 23%;
    width: 30%;
  }
`
const WaitlistSocialButton = styled.a`
  max-height: 15vh;
  max-width: 15vh;
  margin-top: -5em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: -2vh;
    width: 100%;
    height: 100%;
    max-height: 11.5vh;
    max-width: 11.5vh;
  }
`
const WaitlistInstagramButton = styled(WaitlistSocialButton)`
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: -2vh;
  }
`
const WaitlistSocialImg = styled.img`
  object-fit: contain;
  height: 100%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.1s ease-out;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 8em;
  height: 3em;
  width: 100%;
`
const FooterCopy = styled.div`
  padding-left: 2em;

  p {
    font-weight: 500;
  }

  > * {
    &:first-child {
      margin: 0;
    }
  }
`
const FooterImage = styled(logo)`
  height: 60%;
  width: auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 100%;
  }
`
const Seperator = styled.div`
  display: flex;
  height: 3em;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 1.7em;
  }
`
const SectionOne = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10em;
  padding-bottom: 10em;
`
const SectionTwo = withReveal(
  styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    padding-top: 10em;
    padding-bottom: 10em;
  `,
  <Fade />,
)
const SectionTwoCopy = styled.h1`
  font-size: 2.8em;
  letter-spacing: -0.05em;
  line-height: 1em;
  text-align: right;
  width: 40%;
  align-self: flex-end;
  padding-right: 15vh;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    padding-right: 5vh;
    width: 50%;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: 0;
    width: 65%;
    font-size: 1.8em;
    margin-top: -2em;
  }
`
const SofiaIllustration = styled.img`
  height: auto;
  width: 75vh;
  position: absolute;
  margin-top: -15vh;
  margin-left: -10vh;
  max-height: 100%;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    margin-left: -20vh;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 120%;
    margin-left: -40%;
    margin-top: -20%;
  }
`
const Notifications = styled.img`
  height: auto;
  width: 18vw;
  position: absolute;
  margin-top: 25vh;
  margin-left: 50vh;
  max-height: 100%;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    margin-left: 30%;
    width: 35%;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 70%;
    margin-left: 30%;
    margin-top: 50%;
  }
`

const FirstHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const FirstHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`
const HeaderCopy = styled.h2`
  color: #cacaca;
  font-size: 2.4em;
  letter-spacing: -0.05em;
  line-height: 1em;
  padding: 0;
  margin: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.6em;
  }
`
const SubheadingCopy = styled.h1`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 3.2em;
  letter-spacing: -0.05em;
  line-height: 1em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2em;
  }
`
const FirstHeaderDollars = styled.div`
  margin-left: 1em;
  margin-top: 3em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-left: 0;
    margin-top: 1.4em;
  }
`
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .react-reveal {
    display: flex;
    width: 100%;
  }

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`
const LeftCardWrapper = withReveal(
  styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 3em;
    justify-content: space-between;
    width: 100%;

    @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column-reverse;
    }
  `,
  <Fade left />,
)
const RightCardWrapper = withReveal(
  styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 1em;
    justify-content: space-between;
    width: 100%;

    @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column-reverse;
    }
  `,
  <Fade right />,
)
const LeftCardCopy = styled.h2`
  color: #dbdbdb;
  font-size: 2em;
  letter-spacing: -0.05em;
  line-height: 1em;
  text-align: left;
  align-self: center;
  margin: -0.2em 0;
  padding: 0 1em 0 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.4em;
    text-align: right;
    align-self: flex-end;
    margin: 1em 0;
  }
`
const RightCardCopy = styled.h2`
  color: #dbdbdb;
  font-size: 2em;
  letter-spacing: -0.05em;
  line-height: 1em;
  text-align: right;
  align-self: center;
  margin: -0.2em 0;
  padding: 0 0 0 1em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.4em;
    text-align: left;
    align-self: flex-start;
    margin: 0.5em 0;
    padding: 0;
  }
`
const CardTwoCopy = styled.h2`
  color: #dbdbdb;
  font-size: 2em;
  letter-spacing: -0.05em;
  line-height: 1em;
  text-align: right;
  margin: 0.2em 0;
  padding: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.4em;
    text-align: left;
    align-self: flex-start;
    margin: 0.5em 0;
  }
`
const CardTwoGifWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 40em;
  padding-top: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-top: 0.5em;
    width: 80vw;
    max-width: width: 80vw;
  }
`
const CardTwo = styled.img`
  object-fit: contain;
  height: auto;
  max-height: 9vh;
`
const CardTwoReplay = styled(redo)`
  height: auto;
  width: 1.5em;
  margin-top: 0.5em;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: opacity(0.6);
  }

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 1em;
  }
`
const Body = styled.div`
  margin-left: 1%;
`
const CardOne = styled.img`
  height: auto;
  max-width: 70%;
  max-width: 40em;
  max-height: 10vh;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80vw;
  }
`
const CardThree = styled.img`
  height: auto;
  max-width: 70%;
  max-width: 40em;
  max-height: 10vh;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80vw;
  }
`
const CardFour = styled.img`
  height: auto;
  max-width: 70%;
  max-width: 40em;
  max-height: 10vh;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80vw;
  }
`
const CardFive = styled.img`
  height: auto;
  max-width: 70%;
  max-width: 40em;
  max-height: 10vh;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80vw;
  }
`
const CardSix = styled.img`
  height: auto;
  max-width: 70%;
  max-width: 40em;
  max-height: 10vh;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80vw;
  }
`
const Bolt = styled(bolt)`
  position: absolute;
  margin-top: 5vw;
  left: -7vw;
  width: 20vw;
  height: auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 50vw;
    left: -15vw;
    margin-top: 40vw;
  }
`
const InstagramLogo = styled(instagram)`
  height: auto;
  width: 3em;
  cursor: pointer;
  margin-left 50px;
`

const ShareHeaderOne = styled.h1`
  font-size: 3em;
  letter-spacing: -0.05em;
  opacity: 0.9;
  margin-bottom: 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2.3em;
  }
`
const ShareHeaderTwo = styled.h1`
  font-size: 3em;
  letter-spacing: -0.05em;
  opacity: 0.8;
  margin-top: -0.2em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2.3em;
  }
`
const ShareCopyOne = styled.h3`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.4em;
  margin: 0.5em 0 0 0;
  width: 90%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.1em;
  }
`
const Semibold = styled.span`
  font-weight: 600;
`
const ShareCopyTwo = styled.h3`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.2em;
  margin: 1em 0 0 0;
  width: 90%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.1em;
  }
`
const ShareCopyTwoBullets = styled.ul`
  margin-top: 0.5em;
`

const ShareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 8em;
  padding-bottom: 4em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    padding-top: 4em;
    padding-bottom: 1em;
  }
`
const ShareWrapperCopy = styled.div`
  flex-basis: 60%;
  flex-direction: column;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-basis: auto;
  }
`
const ShareWrapperForm = styled.div`
  display: flex;
  flex-basis: 40%;
  flex-direction: row;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-basis: auto;
    padding-top: 4em;
  }
`

const ShareDivider = styled.div`
  height: 100%;
  width: 0.3px;
  background: #ffffff;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 0px;
    width: 0px;
  }
`

const ShareFormWrapper = styled.div`
  margin-left: 2em;
  align-self: center;
  width: 100%;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-left: 0;
    margin-top: 1.5em;
  }
`
const ShareFormNumbers = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: all 0.7s ease-out;
`
const ShareFormContents = styled.div`
  width: 100%;
`

const ShareFormHeader = styled.h3`
  font-size: 2.475em;
  letter-spacing: -0.05em;
  opacity: 0.7;
  margin: 0.1em 0 0 0;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2.2em;
  }
`

const ShareFormHeaderTwo = styled(ShareFormHeader)`
  margin-top: 0.1em;
`

const ShareFormHeaderInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  padding: none;
  background-color: transparent;
  color: #ffffff;
  font-family: 'Eina 01', 'Eina 01 Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 2.475rem;
  letter-spacing: -0.05rem;
  margin: -0.2em 0 0 0;
  padding: 0;
  opacity: 1;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 2.2em;
  }
`

const ChevronSubmit = styled.button`
  all: unset;
  width: 2em;
  height: 2em;
  margin-left: -4em;
`
const Chevron = styled(chevron)`
  height: auto;
  width: 2em;
  opacity: 0.6;
  cursor: not-allowed;
  transition: all 0.3s ease-out;

  ${props =>
    props.active != null &&
    props.active === true &&
    css`
      opacity: 1;
      cursor: pointer;
    `};
`

const ShareFormSubmit = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`

const ShareFormItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.35em;
`

const SnapchatShare = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  width: fit-content;
  height: fit-content;
  margin-top: 4px;

  transform: scale(1.4);
  transform-origin: top left;
`
const ShareFormSocialSelector = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1.5em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: 0.5em;
  }
`
const InstaBtn = styled(instaBtn)`
  height: 80%;
  width: auto;
  cursor: pointer;
  opacity: 0.4;
  padding-left: 0.5em;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.active != null &&
    props.active === true &&
    css`
      opacity: 1;
    `};

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 120%;
  }
`
const TikTokBtn = styled(tiktokBtn)`
  height: 80%;
  width: auto;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.active != null &&
    props.active === true &&
    css`
      opacity: 1;
    `};

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: 120%;
  }
`

// body
export default function IndexPage({ location, data }) {
  const breakpoints = useBreakpoint()
  const videoRef = React.useRef(null)
  const tiktokRef = React.useRef(null)
  const instaRef = React.useRef(null)
  const feedItemRef = React.useRef(null)
  const sectionOneRef = React.useRef(null)
  const waitlistRef = React.useRef(null)
  const [shouldMoveLeft, setShouldMoveLeft] = React.useState(false)
  const [shouldAppear, setShouldAppear] = React.useState(false)
  const [isTikTokVisible, setTikTokVisible] = React.useState(false)
  const [isFeedItemVisible, setFeedItemVisible] = React.useState(false)
  const [userIsTikTokBrowser, setBrowser] = React.useState(false)
  const [selectedSocial, setSelectedSocial] = React.useState('tiktok')

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [social, setSocial] = React.useState('')
  const [username, setUsername] = React.useState('@handle')
  const [recaptchaToken, setRecaptchaToken] = React.useState('')

  const [_, formspreeSubmit] = useForm('xnqwwpkr', {
    data: {
      'g-recaptcha-response': recaptchaToken,
      sharedWith: social,
      username,
      usernameSocial: selectedSocial,
    },
  })

  // Wrapper for Formspree submission to clear state values
  const formspreeSubmitWrapper = async e => {
    console.log('submitting')
    await formspreeSubmit(e)
    setSocial('')
    setRecaptchaToken('')
    console.log(`submitted and reset values to ${social} and ${recaptchaToken}`)
  }

  const formspreeForm = React.useRef(null)
  const usernameInputRef = React.useRef(null)
  const snapchatShareRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const [usernameConfirmed, setConfirmed] = React.useState(false)
  const [usernameTransitioned, setTransitioned] = React.useState(false)

  const reanimateFeedItem = React.useCallback(() => {
    feedItemRef.current.src = feedItem
  }, [])
  const reanimateSocialGifs = React.useCallback(async () => {
    tiktokRef.current.src = tiktok
    instaRef.current.src = insta
  }, [])

  const handleScroll = React.useCallback(() => {
    const position = window.pageYOffset

    const tiktokElem = document.getElementById('ttwrapper')
    const tiktokY = tiktokElem.getBoundingClientRect().top

    const feedItemElem = document.getElementById('feeditemwrapper')
    const feedItemY = feedItemElem.getBoundingClientRect().top

    if (!isTikTokVisible && position > tiktokY) {
      setTikTokVisible(true)
      reanimateSocialGifs()
    }

    if (!isFeedItemVisible && position > feedItemY) {
      setFeedItemVisible(true)
      reanimateFeedItem()
    }
  }, [
    isTikTokVisible,
    isFeedItemVisible,
    reanimateFeedItem,
    reanimateSocialGifs,
  ])
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  React.useEffect(() => {
    if (videoRef == null) {
      return
    }
    if (location.search != null && location.search.includes('tiktok')) {
      setBrowser(true)
      var video = document.querySelector('video')
      enableInlineVideo(video)
    }

    new Promise(resolve => {
      videoRef.current.addEventListener('play', resolve)
    }).then(() => {
      videoRef.current.play()
      new Promise(resolve => setTimeout(resolve, 5120)).finally(() => {
        setShouldMoveLeft(true)
        new Promise(resolve => setTimeout(resolve, 1500)).finally(() =>
          setShouldAppear(true),
        )
      })
    })
  }, [location])

  React.useEffect(() => {
    setTimeout(function () {
      document.getElementById('vid').play()
    }, 1000)
  }, [])

  // Stop Snapchat from submitting form prematurely
  const addParamsToSnapButton = React.useCallback(async () => {
    await new Promise(resolve => setTimeout(resolve, 300))
    if (
      snapchatShareRef.current != null &&
      snapchatShareRef.current.children.length > 0
    ) {
      console.log('changing values')
      snapchatShareRef.current.children[0].type = 'button'
    } else {
      console.log('not changing values')
    }
  }, [])

  // Append snap script to page once div is visible (or it won't show!)
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://sdk.snapkit.com/js/v1/create.js'
    script.async = true
    script.id = 'snapkit-creative-kit-sdk'
    if (usernameTransitioned === true) {
      document.body.appendChild(script)
      addParamsToSnapButton()
    }
    return () => {
      try {
        document.body.removeChild(script)
      } catch (e) {}
    }
  }, [usernameTransitioned, addParamsToSnapButton])

  // Set state values on social click for Formspree
  const socialListener = async e => {
    if (e == null) {
      return
    }
    if (e.type === 'touchstart') {
      if (
        e.target.ownerSVGElement != null &&
        [...e.target.ownerSVGElement.classList].includes('instagram-share')
      ) {
        setSocial('instagram')
      } else if (
        e.target.offsetParent != null &&
        [...e.target.offsetParent.classList].includes(
          'snapchat-creative-kit-share',
        )
      ) {
        setSocial('snapchat')
      } else {
        return
      }
    } else if (e.path != null && e.path.length > 1) {
      const path = e.path.slice(0, 4)
      if (
        path.filter(entry =>
          entry.classList.contains('snapchat-creative-kit-share'),
        ).length > 0
      ) {
        setSocial('snapchat')
      } else if (
        path.filter(entry => entry.classList.contains('instagram-share'))
          .length > 0 &&
        isMobile
      ) {
        setSocial('instagram')
      }
    } else {
      return
    }

    // Set recaptcha token
    if (recaptchaToken === '') {
      const result = await executeRecaptcha('homepage')
      setRecaptchaToken(result)
    }
  }
  useEventListener('click', socialListener)
  useEventListener('touchstart', socialListener)

  // Submit form once username, socials, and recaptcha are confirmed
  React.useEffect(() => {
    if (usernameConfirmed === true && social !== '' && recaptchaToken !== '') {
      formspreeForm.current &&
        formspreeForm.current.dispatchEvent(
          new Event('submit', { bubbles: true, cancelable: true }),
        )
    }
  }, [usernameConfirmed, social, recaptchaToken])

  // Copy clipboard and open IG modal
  const handleInstagram = async () => {
    if (isMobile) {
      // Copy referral url to clipboard
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(`https://umber.co?ref=${username}`)
      } else {
        // text area method if not supported
        let textArea = document.createElement('textarea')
        textArea.value = `https://umber.co?ref=${username}`
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        document.execCommand('copy')
        textArea.remove()
      }
    }

    // Open modal
    setOpen(true)
  }

  // Change modal state to closed
  const closeModal = success => {
    if (success) {
      window.open('instagram://story-camera')
    }
    setOpen(false)
  }

  return (
    <Page desktop={breakpoints.desktop}>
      <Seo title="umber" description="give your bank account superpowers ⚡" />
      <Header>
        <HeaderImage alt="umber pounds logo" />
        <HeaderLink onClick={() => scrollTo('#waitlist')}>
          jump to the waitlist ☀︎
        </HeaderLink>
      </Header>
      <Body>
        <Intro>
          <IntroVideoWrapper>
            <IntroVideo shouldMoveLeft={shouldMoveLeft}>
              <video
                muted
                autoPlay
                playsInline
                onLoad={() => this.play()}
                ref={videoRef}
                controls={userIsTikTokBrowser}
                id="vid">
                <source
                  src="https://umbertest.b-cdn.net/tto/intro2.mp4"
                  type="video/mp4"
                />
                <source src={intromp4lq} type="video/mp4" />
                <source src={intromp4hq} type="video/mp4" />
              </video>
            </IntroVideo>
          </IntroVideoWrapper>
          <IntroCopy shouldAppear={shouldAppear}>
            <IntroCopyImage alt="meet umber." />
            <IntroArrow
              alt="arrow down"
              onClick={() => scrollTo('#section-one')}
            />
          </IntroCopy>
        </Intro>
        <SectionOne id="section-one" ref={sectionOneRef}>
          <Bolt />
          <FirstHeaderContainer>
            <FirstHeaderWrapper>
              <HeaderCopy>you've already got a bank.</HeaderCopy>
              <SubheadingCopy>
                we give it&nbsp;
                <span style={{ color: '#FEC933' }}>superpowers</span>
              </SubheadingCopy>
            </FirstHeaderWrapper>
            <FirstHeaderDollars>
              <FlyingDollars scale={breakpoints.desktop ? 1 : 0.6} />
            </FirstHeaderDollars>
          </FirstHeaderContainer>
          <CardsWrapper>
            <LeftCardWrapper>
              <LeftCardCopy>
                send money <br />
                in seconds <br />
                with @handles
              </LeftCardCopy>
              <CardOne src={cardone} />
            </LeftCardWrapper>
            <RightCardWrapper>
              <CardTwoCopy>
                see where <br />
                your money’s <br />
                actually gone
              </CardTwoCopy>
              <CardTwoGifWrapper id="feeditemwrapper">
                <CardTwo
                  src={feedItem}
                  alt="Umber feed item animation"
                  ref={feedItemRef}
                />
                <CardTwoReplay onClick={() => reanimateFeedItem()} />
              </CardTwoGifWrapper>
            </RightCardWrapper>
            <LeftCardWrapper>
              <LeftCardCopy>
                peep your <br />
                upcoming <br />
                payments
              </LeftCardCopy>
              <CardThree src={cardthree} />
            </LeftCardWrapper>
            <RightCardWrapper>
              <RightCardCopy>
                ask for ious{' '}
                <span style={{ color: '#C4C4C4' }}>
                  and <br />
                  actually get your <br />
                  money back
                </span>
              </RightCardCopy>
              <CardFour src={cardfour} />
            </RightCardWrapper>
            <LeftCardWrapper>
              <LeftCardCopy>
                do simple <br />
                weekly <br />
                budgeting
              </LeftCardCopy>
              <CardFive src={cardfive} />
            </LeftCardWrapper>
            <RightCardWrapper>
              <RightCardCopy>
                see your <br />
                impact 🌍
              </RightCardCopy>
              <CardSix src={cardsix} />
            </RightCardWrapper>
          </CardsWrapper>
        </SectionOne>
        <SectionTwo>
          <SectionTwoCopy>
            help us build{' '}
            <span style={{ color: '#eee' }}>
              a new <br />
              kind of banking <br />
              that works{' '}
            </span>
            <u>for you</u>.
          </SectionTwoCopy>
          <SofiaIllustration src={sofia} />
          <Notifications src={notifications} />
        </SectionTwo>
        <Seperator>
          <FooterImage alt="umber pounds logo" />
        </Seperator>
        <Fade>
          <Waitlist>
            <WaitlistButton id="waitlist" ref={waitlistRef}>
              <WaitlistButtonCopy>WAITLIST</WaitlistButtonCopy>
            </WaitlistButton>
            <WaitlistHeadingCopy>be the first to try us...</WaitlistHeadingCopy>
            <WaitlistWrapper>
              <WaitlistCopyWrapper>
                <WaitlistSecondCopy>
                  join the waitlist by following us on tiktok or ig.
                </WaitlistSecondCopy>
                <WaitlistThirdCopy>we’ll dm you.</WaitlistThirdCopy>
              </WaitlistCopyWrapper>
              <WaitlistSocialBtnWrapper>
                <WaitlistSocialButton
                  id="ttwrapper"
                  href="https://tiktok.com/@umber">
                  <WaitlistSocialImg
                    src={tiktok}
                    alt="TikTok logo"
                    ref={tiktokRef}
                  />
                </WaitlistSocialButton>
                <WaitlistInstagramButton href="https://instagram.com/umber">
                  <WaitlistSocialImg
                    src={insta}
                    alt="Instagram logo"
                    ref={instaRef}
                  />
                </WaitlistInstagramButton>
              </WaitlistSocialBtnWrapper>
            </WaitlistWrapper>
            <ShareWrapper>
              <ShareWrapperCopy>
                <ShareHeaderOne>ish...</ShareHeaderOne>
                <ShareHeaderTwo>insiders go first 🔐</ShareHeaderTwo>
                <ShareCopyOne>
                  want a <Semibold>guaranteed spot in the beta</Semibold> and a{' '}
                  <Semibold>chance to win over £1000</Semibold>?
                </ShareCopyOne>
                <ShareCopyTwo>
                  share umber to your story and get...
                  <ShareCopyTwoBullets>
                    <li>a reserved place in the beta</li>
                    <li>the chance to join our insiders board</li>
                    <li>an exclusive ‘early adopter’ tag in the app</li>
                    <li>a chance of winning over £1000</li>
                  </ShareCopyTwoBullets>
                </ShareCopyTwo>
              </ShareWrapperCopy>
              <ShareWrapperForm>
                <ShareDivider />
                <ShareFormWrapper>
                  <ShareFormNumbers>
                    <CircleNumber
                      number={1}
                      opacity={usernameConfirmed ? 0.4 : 1}
                    />
                    <CircleNumber
                      number={2}
                      opacity={usernameConfirmed ? 1 : 0.4}
                      style={{ marginLeft: '0.7em' }}
                    />
                  </ShareFormNumbers>
                  <ShareFormContents>
                    <Transition
                      show={!usernameConfirmed}
                      enter="transition-opacity duration-75"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setTransitioned(true)}>
                      <ShareFormSocialSelector>
                        <TikTokBtn
                          alt="tiktok button"
                          active={selectedSocial === 'tiktok'}
                          onClick={e => {
                            e.preventDefault()
                            setSelectedSocial('tiktok')
                          }}
                        />
                        <InstaBtn
                          alt="instagram button"
                          active={selectedSocial === 'insta'}
                          onClick={e => {
                            e.preventDefault()
                            setSelectedSocial('insta')
                          }}
                        />
                      </ShareFormSocialSelector>
                      <ShareFormHeader>
                        what’s your {selectedSocial}
                      </ShareFormHeader>
                      <form
                        style={{ display: 'flex' }}
                        onSubmit={async e => {
                          e.preventDefault()
                          if (username !== '@handle') {
                            setConfirmed(true)
                          }
                        }}>
                        <ShareFormHeaderInput
                          ref={usernameInputRef}
                          placeholder="@handle"
                          value={username}
                          onChange={e => {
                            e.preventDefault()
                            setUsername(
                              '@' + e.target.value.replaceAll('@', ''),
                            )
                          }}
                          type={'text'}
                        />
                        <ChevronSubmit type="submit" value="submit">
                          <Chevron active={username !== '@handle'} />
                        </ChevronSubmit>
                      </form>
                    </Transition>
                    <Transition
                      show={usernameTransitioned}
                      enter="transition-opacity duration-150"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <form
                        ref={formspreeForm}
                        onSubmit={formspreeSubmitWrapper}>
                        <ShareFormHeaderTwo>
                          add to your story
                        </ShareFormHeaderTwo>
                        <ShareFormItems>
                          <SnapchatShare
                            className="snapchat-creative-kit-share"
                            data-theme="dark"
                            data-size="large"
                            data-text="true"
                            data-share-url={`https://umber.co?ref=${username}`}
                            ref={snapchatShareRef}
                          />
                          <InstagramLogo
                            alt="instagram stories"
                            className="instagram-share"
                            onClick={handleInstagram}
                          />
                        </ShareFormItems>
                        <ShareFormSubmit type="submit" value="submit" />
                      </form>
                    </Transition>
                  </ShareFormContents>
                </ShareFormWrapper>
              </ShareWrapperForm>
            </ShareWrapper>
          </Waitlist>
        </Fade>
      </Body>
      <Modal
        open={open}
        closedModalWithSuccess={closeModal}
        desktop={!isMobile}
      />
      <Footer>
        <FooterImage alt="umber pounds logo" />
        <FooterCopy>
          <p>
            © 2022 Umber{' '}
            <span style={{ color: '#A0A0A0' }}>(Umber Money Limited)</span>
          </p>
          <p>
            Umber is pre-launch and does not currently offer any financial
            products or services to the public, and is not currently regulated.
          </p>
          <p>
            By using this site you agree to our{' '}
            <a href="https://umber.co/privacy">Privacy Policy</a> and{' '}
            <a href="https://umber.co/terms">Terms</a>.
            <br />
            This site is protected by reCAPTCHA, so Google's{' '}
            <a href="https://policies.google.com/privacy">
              Privacy Policy
            </a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            also apply.
          </p>
        </FooterCopy>
      </Footer>
    </Page>
  )
}
